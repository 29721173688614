/**
 * @generated SignedSource<<5c84b855e1f9594e3c598e4685be9c82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AIBrandVoiceAllCapsUsage = "ALL_CAPS_USAGE_ADAPTIVE" | "ALL_CAPS_USAGE_FREQUENTLY" | "ALL_CAPS_USAGE_NEVER" | "ALL_CAPS_USAGE_SPARINGLY" | "%future added value";
export type AIBrandVoiceEmojiFrequency = "EMOJI_FREQUENCY_ADAPTIVE" | "EMOJI_FREQUENCY_FREQUENTLY" | "EMOJI_FREQUENCY_NEVER" | "EMOJI_FREQUENCY_SPARINGLY" | "%future added value";
export type AIBrandVoiceProductTitleFormatting = "PRODUCT_TITLE_FORMATTING_LOWER_CASE" | "PRODUCT_TITLE_FORMATTING_TITLE_CASE" | "PRODUCT_TITLE_FORMATTING_UPPER_CASE" | "%future added value";
export type AIBrandVoiceUrgency = "URGENCY_ADAPTIVE" | "URGENCY_FREQUENTLY" | "URGENCY_NEVER" | "URGENCY_SPARINGLY" | "%future added value";
export type OpenAIModel = "OPENAI_MODEL_GPT_3_5_TURBO" | "OPENAI_MODEL_GPT_4" | "OPENAI_MODEL_GPT_4_300_TEST" | "OPENAI_MODEL_GPT_4_O" | "OPENAI_MODEL_GPT_4_TURBO" | "OPENAI_MODEL_UNKNOWN" | "OPEN_AI_MODEL_GPT_4_0_MINI" | "%future added value";
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type GenerateAITextV2Input = {
  brandVoiceSettings?: AIBrandVoiceSettingsInputType | null;
  companyId: string;
  messages?: ReadonlyArray<ChatMessageInput> | null;
  metadata: GeneratedTextInputMetadata;
  useMessageEvaluation?: boolean | null;
};
export type AIBrandVoiceSettingsInputType = {
  allCapsCount?: number | null;
  allCapsUsage?: AIBrandVoiceAllCapsUsage | null;
  characterCount?: number | null;
  emojiCount?: number | null;
  emojiFrequency?: AIBrandVoiceEmojiFrequency | null;
  exclusionWords?: ReadonlyArray<string> | null;
  productTitleFormatting?: AIBrandVoiceProductTitleFormatting | null;
  toneKeywords?: ReadonlyArray<string> | null;
  urgency?: AIBrandVoiceUrgency | null;
  wordCount?: number | null;
};
export type ChatMessageInput = {
  content: string;
  role: string;
};
export type GeneratedTextInputMetadata = {
  maxTokens?: number | null;
  n?: number | null;
  origin?: string | null;
  temperature?: number | null;
  template?: GenerativeAITextTemplateInput | null;
  topP?: number | null;
  vendorOptions?: TextGenVendorOptions | null;
};
export type GenerativeAITextTemplateInput = {
  templateName: string;
  version: string;
};
export type TextGenVendorOptions = {
  vendorOptions?: TextGenVendorOptionsVendorOptions | null;
};
export type TextGenVendorOptionsVendorOptions = {
  openaiOptions?: OpenAIOptions | null;
};
export type OpenAIOptions = {
  model: OpenAIModel;
};
export type BrandVoicePreviewGenerateAITextMutation$variables = {
  input: GenerateAITextV2Input;
};
export type BrandVoicePreviewGenerateAITextMutation$data = {
  readonly generateAITextV2: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
  } | {
    readonly __typename: "GenerateAITextV2Success";
    readonly generatedTextResponses: ReadonlyArray<{
      readonly id: string;
      readonly text: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type BrandVoicePreviewGenerateAITextMutation$rawResponse = {
  readonly generateAITextV2: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
  } | {
    readonly __typename: "GenerateAITextV2Success";
    readonly generatedTextResponses: ReadonlyArray<{
      readonly id: string;
      readonly text: string;
    }>;
  } | {
    readonly __typename: string;
  } | null;
};
export type BrandVoicePreviewGenerateAITextMutation = {
  rawResponse: BrandVoicePreviewGenerateAITextMutation$rawResponse;
  response: BrandVoicePreviewGenerateAITextMutation$data;
  variables: BrandVoicePreviewGenerateAITextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "generateAITextV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AIGeneratedText",
            "kind": "LinkedField",
            "name": "generatedTextResponses",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "GenerateAITextV2Success",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "DefaultErrorFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandVoicePreviewGenerateAITextMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrandVoicePreviewGenerateAITextMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "307788af7cc40fae115039f0b5b84d06",
    "id": null,
    "metadata": {},
    "name": "BrandVoicePreviewGenerateAITextMutation",
    "operationKind": "mutation",
    "text": "mutation BrandVoicePreviewGenerateAITextMutation(\n  $input: GenerateAITextV2Input!\n) {\n  generateAITextV2(input: $input) {\n    __typename\n    ... on GenerateAITextV2Success {\n      generatedTextResponses {\n        id\n        text\n      }\n    }\n    ... on DefaultErrorFailure {\n      status\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3c1b3250c37379853e3b6725ad2aa14e";

export default node;
